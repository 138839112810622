import React from 'react';
import Box from '@material-ui/core/Box';
import {ReactComponent as MetroSVG}from "../image/metro.svg"
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    clip: {
        clipPath: "circle(20% at center)",
    },
    svgWrapper: {
        width: "70%",
        margin: "0 auto"
    }
}));

const LoadingMetro = () => { 
   const classes = useStyles();

   return( 
    <Box display="flex" className={classes.svgWrapper}> 
        <MetroSVG className={classes.clip}/>
        {/* <MetroSVG classes={classes.root}/> */}
    </Box>
    )
}



export default LoadingMetro;