import React, { useEffect } from "react";
import gql from "graphql-tag";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useQuery } from '@apollo/react-hooks';
import  MetroLoading from "./components/LoadingMetro";

const GET_ME = gql`
  query {
    me {
      id
      email
      username
      locale
      authorized
    }
  }
`;

const UserContext = React.createContext(
  {}
);

export const UserConsumer = UserContext.Consumer;

const UserProvider = (props) => {

    const { loading, error, data } = useQuery(GET_ME);
    
    if (error) return "An error has occurred. Try to refresh the page.";
    // if (!data || loading) return <LinearProgress />;
    if (!data || loading) return <MetroLoading />;

    return (
        <UserContext.Provider value={data}>
            {props.children}
        </UserContext.Provider>
    )
}

export default UserContext

export { UserProvider }