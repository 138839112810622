import React, { useContext, lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import './App.css';

// import ExternalLogin from "./pages/Login/ExternalLogin";
// import ExternalRegister from "./pages/Login/ExternalRegister"; 
// import PasswordRecovery from "./pages/Login/PasswordRecovery";
// import PasswordReset from "./pages/Login/PasswordReset";
// import Homepage from "./pages/Homepage/"; 
import UserContext from './UserContext'
import MetroLoading from "./components/LoadingMetro";
// import Cashbox from './pages/Cashbox';

const Homepage = lazy(() => import('./pages/Homepage/'))
const PasswordReset = lazy(() => import('./pages/Login/PasswordReset'))
const PasswordRecovery = lazy(() => import('./pages/Login/PasswordRecovery'))
const ExternalRegister = lazy(() => import('./pages/Login/ExternalRegister'))
const ExternalLogin = lazy(() => import('./pages/Login/ExternalLogin'))
const Cashbox = lazy(() => import('./pages/Cashbox'))

const App = () => {
  const userCtx = useContext(UserContext);

  if (userCtx.me) {
    return (
      <Switch>
        <Route exact path="/cashbox" render={
          props => (
            <Suspense fallback={<MetroLoading />}>
              <Cashbox {...props} />
            </Suspense>
          )
        }>
        </Route>

        <Suspense fallback={<MetroLoading />}>
          <Route path="/" component={Homepage} />
        </Suspense>
      </Switch>
    )
  }

  return (
    <>
      <Switch>
        <Route exact path="/login/external"
          render={props => (
            <Suspense fallback={<MetroLoading />}>
              <ExternalLogin {...props} />
            </Suspense>
          )} />

        <Route exact path="/register/external/:token/:email"
          render={props => (
            <Suspense fallback={<MetroLoading />}>
              <ExternalRegister {...props} />
            </Suspense>
          )} />

        <Route exact path="/password/recovery"
          render={props => (
            <Suspense fallback={<MetroLoading />}>
              <PasswordRecovery {...props} />
            </Suspense>
          )} />

        <Route exact path="/password/reset/:token/:email"
          render={props => (
            <Suspense fallback={<MetroLoading />}>
              <PasswordReset {...props} />
            </Suspense>
          )} />

        <Route
          render={props => (
            <Suspense fallback={<MetroLoading />}>
              <ExternalLogin {...props} />
            </Suspense>
          )} />

      </Switch>
    </>
  );
}

export default App;
