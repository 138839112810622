import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "./UserContext";
import DayjsUtils from '@date-io/dayjs';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createMuiTheme, ThemeProvider, responsiveFontSizes} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import dayjs from 'dayjs' // ES 2015
import 'dayjs/locale/cs' // load on demand

import { ApolloClient, InMemoryCache } from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import { from } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';


// const { createUploadLink } = require('apollo-upload-client')

dayjs.locale('cs')

let theme = createMuiTheme({
   typography: {
    fontFamily: [
      'TriviaSeznam',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {fontWeight: 700},
    h2: {fontWeight: 700},
    h3: {
      fontWeight: 700,
    },
    h4: {fontWeight: 700},
    h5: {fontWeight: 700},
    h6: {
      fontWeight: 700,
    },
    button: {
      textTransform: "none",
      fontWeight: 700,
    }
  },
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#CC0000",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
           backgroundColor: "white",
        }
      }
    }
  }
});

theme = responsiveFontSizes(theme);

require('es6-promise').polyfill();
require('isomorphic-fetch');

const cred = "include"

const link = createUploadLink({
      uri: process.env.REACT_APP_SERVER_BASE_PATH + "/graphql",
      credentials: cred
    });

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  // uri: process.env.REACT_APP_SERVER_BASE_PATH + "/graphql",
  cache: new InMemoryCache({ addTypename: false}),
  // credentials: cred, 
  link: from([errorLink, link]),
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename={process.env.REACT_APP_CLIENT_BASE_PATH}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <UserProvider>
            <MuiPickersUtilsProvider utils={DayjsUtils} locale='cs'>
              <App />
            </MuiPickersUtilsProvider>
          </UserProvider>
        </ThemeProvider>
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
